import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import { Container, Grid, Button, Card, TextField, Stack } from '@mui/material';
import {  add_expense_sub_category, get_all_sales_revenue, get_allproducts, get_expense_category, update_expense_category, update_expense_sub_category,} from '../../Services/APIService';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SnackBar from '../../Components/AdminDashBoardComps/Snackbar';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Heading from '../../Components/Heading/Heading';

import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row  , i , getAllExpense , setMessage, setOpen, setStatus, setColor} = props;
  const [openAccord, setOpenAccord] = React.useState(false);

  const [newSubCategory , setnewSubCategory] = useState('');
  const [newSubCategory1 , setnewSubCategory1] = useState('');
  const [newCategory , setnewCategory] = useState('');
  const [editMode , setEditMode] = useState(false);
  const [editId , setEditId] = useState('');
  const [SelectedDelete , setSelectedDelete] = useState('');

  const [openDialog , setOpenDialog] = useState(false);
  const [dialogDataName , setDialogDataName] = useState('')
  const [dialogDataId , setDialogDataId] = useState('')

 


 

 
  

 
 




  return (
    <React.Fragment>
      
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>{i + 1}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
           
          >
           
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {
          
          
          
          <Typography sx={{textAlign:"center"}}>{row.orderType}</Typography> 
          }
        </TableCell>
        <TableCell align="center">{row.saleAmount}</TableCell>
        <TableCell align="center">{row.gst}</TableCell>
        <TableCell align="center">{row.pst}</TableCell>
       
        </TableRow>
      
    </React.Fragment>
  );
}




export default function SalesReport() {


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [ExpenseCategory, setExpenseCategory] = useState([]);
    const [search, setSearch] = useState('');
    const [openExpenseDialog , setOpenExpenseDialog] = useState('');
    const [openExpenseDeleteDialog, setOpenExpenseDeleteDialog] = useState('');
    const [ExpenseCategoryId, setExpenseCategoryId] = useState('');
    const [ExpenseCategoryName, setExpenseCategoryName] = useState('');
    const[yearnumber , setYearNumber] = useState('');
    const textFieldYearRef = useRef();


    const [showMonth , setShowMonth ] = useState(false);
    const [showYear , setShowYear ] = useState(true);
    const [fromDate , setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const[currentMode , setCurentMode] = useState('Yearly');
    const textFieldRef = useRef();
    const textFieldFromRef = useRef();


    const getAllExpense  = () =>{
        const sendData = new FormData();
        axios({
          method: "POST",
          url: get_all_sales_revenue,
        
        }).then(res => {
          if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
          } else {
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setExpenseCategory(res.data.data);
          }
        }).catch(err => {
          console.log(err)
        });
      }
     
       useEffect(() => {
         getAllExpense();
       }, []);

       const getFilteredYearData = (year) =>{
        const sendData = new FormData();
        sendData.append('saleYear', year !== undefined ? year : '')
        axios({
          method: "POST",
          url: get_all_sales_revenue,
            data:sendData
        }).then(res => {
          if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
          } else {
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setExpenseCategory(res.data.data);
          }
        }).catch(err => {
          console.log(err)
        });   
       }


       
       const getFilteredMonthData = (year) =>{
        if( textFieldRef?.current?.value !== undefined &&  textFieldRef?.current?.value !== '' &&  textFieldRef?.current?.value !== null){
        const sendData = new FormData();
        sendData.append('checkBox', 'Monthly');
        sendData.append('fromDate', textFieldFromRef?.current?.value !== undefined ? textFieldFromRef?.current?.value : '');
        sendData.append('toDate', textFieldRef?.current?.value !== undefined ? textFieldRef?.current?.value : '');
        axios({
          method: "POST",
          url: get_all_sales_revenue,
            data:sendData
        }).then(res => {
          if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
          } else {
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setExpenseCategory(res.data.data);
          }
        }).catch(err => {
          console.log(err)
        });   
      }
       }

       const ChangeMode = (mode) =>{
        setCurentMode(mode);
        if (mode === 'Yearly'){
          setCurentMode('Yearly');
         setShowYear(true);
        setShowMonth(false);
        getFilteredYearData()
        }
        else if (mode === 'Monthly'){
          setCurentMode('Monthly')
          setShowMonth(true);
          setShowYear(false);
          getFilteredMonthData();
        }
      }



       const handleOpenAddDialog = () => {
        setOpenExpenseDialog(true);
         }


  return (
    <Box p={3}>
    
            <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />


        <Box mb={3} mt={1}>
        <Grid container>
       
        <Grid item xs={12} justifyContent='end' display='flex'>
      <Grid container spacing={3}>
      <Grid item xs={12} lg={12} justifyContent='end' display='flex'>
      <Box sx={{cursor:'pointer'}} display='flex' flexDirection='row' gap={3}>
       <Typography sx={{color: currentMode === 'Monthly' ? 'green' : '#faa634' , cursor:'pointer'}}  onClick={()=>ChangeMode('Monthly')}>Monthly</Typography>
       <Typography sx={{color: currentMode === 'Yearly' ? 'green' : '#faa634' , cursor:'pointer'}}  onClick={()=>ChangeMode('Yearly')}>Yearly</Typography>
    </Box>
      </Grid>
      <Grid item xs={12} lg={12} justifyContent='end' display='flex'>
      <Box>
      {
        showMonth &&
        <Box display='flex' flexDirection={{lg:'row', md:'row', sm:'row', xs:'column'}} gap={2}>
        <TextField
        type="date"
        id="start" 
        size='small'
        name="trip-start"
        inputRef={textFieldFromRef}
        onChange={(e) => {
        setFromDate(e.target.value);
        getFilteredMonthData();
        }}
        label='From date'
        required
        InputLabelProps={{
          shrink: true,
        }}
          />
    
          <TextField
          type="date"
          id="end" 
          size='small'
          name="trip-end"
          inputRef={textFieldRef}
          onChange={(e) => {
          setToDate(e.target.value);
            getFilteredMonthData();
          }}
          label='To date'
          required
          InputLabelProps={{
            shrink: true,
          }}
            />
       </Box>
      }
      {
        showYear &&
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
        views={['year']}
        label="Year"
        openTo='year'
        slotProps={{ textField: { size: 'small' } }}
        inputRef={textFieldYearRef}
        dateFormat="yyyy"
        onChange={(newValue) => {
        const date = new Date(newValue);
        const YearDate = date.getFullYear();
        setYearNumber(YearDate);
        getFilteredYearData(YearDate);
        }}
        renderInput={(params) => <TextField 
        {...params} 
        helperText={null}
        inputProps={{
        ...params.inputProps,
        readOnly: true
        }} size='small' />}
        />
        </LocalizationProvider>
      }
      </Box>
      </Grid>
      </Grid>
      </Grid>
        </Grid>
        </Box>


    <TableContainer component={Card} sx={{boxShadow:6}}>
      <Table aria-label="collapsible table">
        <TableHead  sx={{ whiteSpace: 'nowrap' , bgcolor:'#616e80' }}>
          <TableRow>
          <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600  }}>#</TableCell>
          <TableCell />
            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600  }}>Sales Category</TableCell>
            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600  }} align="center">Amount</TableCell>
            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600  }} align="center">GST</TableCell>
            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600  }} align="center">PST</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {ExpenseCategory?.map((row , index) => (
            <Row key={row.name} row={row} i={index} getAllExpense={getAllExpense} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  );
}
