import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { expense_graph } from "../../../Services/APIService";
import { Chart } from "react-google-charts";
import { Box, Typography } from "@mui/material";

export default function ExpensePieChart() {
  const [expenseCategory, setExpenseCategory] = useState([]);
  const [currentMode, setCurrentMode] = useState("Today");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const textFieldFromRef = useRef();
  const textFieldToRef = useRef();

  const fetchExpenseData = async (checkBox, additionalData = {}) => {
    try {
      const sendData = new FormData();
      sendData.append("checkBox", checkBox);
      Object.entries(additionalData).forEach(([key, value]) => {
        sendData.append(key, value);
      });
      const response = await axios.post(expense_graph, sendData);
      if (response.data.error) {
        setMessage(response.data.message);
        setOpen(true);
      } else {
        setMessage(response.data.message);
        setOpen(true);
        setData(response.data.data || []);
      }
    } catch (error) {
      console.error("Error fetching expense data:", error);
    }
  };

  const getFilteredTodayData = () => fetchExpenseData("Today");
  const getFilteredWeekData = (week = "") => fetchExpenseData("Weekly", { weekNumber: week });
  const getFilteredMonthData = () => {
    if (textFieldToRef.current?.value) {
      fetchExpenseData("Monthly", {
        fromDate: textFieldFromRef.current?.value || "",
        toDate: textFieldToRef.current?.value,
      });
    }
  };

  const changeMode = (mode) => {
    setCurrentMode(mode);
    if (mode === "Today") {
      getFilteredTodayData();
    } else if (mode === "Weekly") {
      getFilteredWeekData();
    } else if (mode === "Monthly") {
      getFilteredMonthData();
    }
  };

  const getAllExpense = async () => {
    try {
      const sendData = new FormData();
      sendData.append("checkBox", "");
      const response = await axios.post(expense_graph, sendData);
      setExpenseCategory(response.data.data || []);
    } catch (error) {
      console.error("Error fetching all expenses:", error);
    }
  };

  useEffect(() => {
    getAllExpense();
  }, []);

  const datas = [
    ["Expense", "Amount"],
    ...expenseCategory.map((item) => [
      item.expenseCategory || "Unnamed Category",
      item.totalAmount > 0 ? item.totalAmount : 0.1, // Small placeholder value for zero
    ]),
  ];

  const options = {
    is3D: true,
    title: "Expense Distribution",
    pieSliceText: "value",
    sliceVisibilityThreshold: 0, // Ensures all categories, including small ones, are shown
    legend: { textStyle: { color: "#616e80" } },
  };

  return expenseCategory.length > 0 ? (
    <>
      <Box display="flex" justifyContent="end" gap={3} mb={2}>
        {["Today", "Weekly", "Monthly"].map((mode) => (
          <Typography
            key={mode}
            onClick={() => changeMode(mode)}
            sx={{
              color: currentMode === mode ? "green" : "#faa634",
              cursor: "pointer",
              fontWeight: currentMode === mode ? "bold" : "normal",
            }}
          >
            {mode}
          </Typography>
        ))}
      </Box>
      {currentMode === "Monthly" && (
        <Box mt={2} display="flex" justifyContent="end" gap={2}>
          <input
            type="date"
            ref={textFieldFromRef}
            placeholder="From Date"
            style={{ padding: "5px", borderRadius: "5px", border: "1px solid #ccc" }}
          />
          <input
            type="date"
            ref={textFieldToRef}
            placeholder="To Date"
            style={{ padding: "5px", borderRadius: "5px", border: "1px solid #ccc" }}
          />
          <button
            onClick={getFilteredMonthData}
            style={{
              padding: "5px 10px",
              borderRadius: "5px",
              backgroundColor: "#faa634",
              color: "#fff",
              border: "none",
              cursor: "pointer",
            }}
          >
            Filter
          </button>
        </Box>
      )}
      <Chart
        chartType="PieChart"
        data={datas}
        options={options}
        width={"100%"}
        height={"400px"}
      />
    </>
  ) : (
    <p>{open ? message : "Loading or no data available."}</p>
  );
}
