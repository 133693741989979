import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, CircularProgress, Container, TextField, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import ReportGraph from './ReportGraph';
import ExportReport from './ExportReport';
import {
    
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,

    Paper,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
  } from '@mui/material';
import axios from 'axios';
import { get_all_sales_revenue } from '../../Services/APIService';
import SalesReport from './SalesReport';

  
export default function ReportTab() {

    const [value, setValue] = React.useState(0);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();

    const [filterMode, setFilterMode] = useState('Yearly'); // 'Yearly' or 'Monthly'
    const [saleYear, setSaleYear] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [salesData, setSalesData] = useState([]);
    const [error, setError] = useState(null);
  
    // Handle Filter Mode Change
    const handleFilterModeChange = (mode) => {
      setFilterMode(mode);
      setSaleYear('');
      setFromDate('');
      setToDate('');
      setError(null);
    };
  
    // Fetch Data from API
    const fetchSalesData = async (filters = {}) => {
      setIsLoading(true);
      setError(null);
  
      const formData = new FormData();
      if (filters.saleYear) formData.append('saleYear', filters.saleYear);
      if (filters.fromDate) formData.append('fromDate', filters.fromDate);
      if (filters.toDate) formData.append('toDate', filters.toDate);
  
      try {
        const response = await axios.post(get_all_sales_revenue, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
  
        if (response.data) {
          setSalesData(response.data.data || []);
        } else {
          setSalesData([]);
        }
      } catch (err) {
        setError('Failed to fetch sales data. Please try again later.');
        setSalesData([]);
      } finally {
        setIsLoading(false);
      }
    };
  
    // Fetch all data initially
    useEffect(() => {
      fetchSalesData(); // Fetch all data without filters on first render
    }, []);
  
    // Filtered Fetch on Button Click
    const applyFilters = () => {
      const filters = {};
      if (filterMode === 'Yearly' && saleYear) filters.saleYear = saleYear;
      if (filterMode === 'Monthly' && fromDate && toDate) {
        filters.fromDate = fromDate;
        filters.toDate = toDate;
      }
      fetchSalesData(filters);
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 1 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }


    return (
        <Container>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={4} mb={1}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="success"
                    textColor="inherit"
                    aria-label="full width tabs example"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                >
                      <Tab
                        label="Sales"
                        sx={{
                            bgcolor: value === 0 && '#00bca4',
                            border: value === 0 && '1px solid silver',
                            borderRadius: value === 0 && '4px',
                            textTransform: 'none'
                        }}
                        {...a11yProps(0)} />
                    <Tab
                        label="Reports"
                        sx={{
                            bgcolor: value === 1 && '#00bca4',
                            border: value === 1 && '1px solid silver',
                            borderRadius: value === 1 && '4px',
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)} />
                    <Tab
                        label="Export Report"
                        sx={{
                            bgcolor: value === 2 && '#00bca4',
                            border: value === 2 && '1px solid silver',
                            borderRadius: value === 2 && 2,
                            textTransform: 'none'
                        }}
                        {...a11yProps(2)}
                    />
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
         <SalesReport/>
            </TabPanel>



            <TabPanel value={value} index={1} >
            <ReportGraph />
            </TabPanel>


            <TabPanel value={value} index={2}>
             <ExportReport />
            </TabPanel>
        </Container>
    )
}
